import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { isFunction } from 'lodash-es';
import React from 'react';
import { Button, ButtonProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { iconNames } from '../../atoms/icon/generated';
import Icon from '../../atoms/icon/icon';

export const iconButton = cva(
  'rac-disabled:pointer-events-none rac-disabled:cursor-not-allowed cursor-pointer rounded-full text-xs transition-colors',
  {
    variants: {
      size: {
        xs: 'p-1',
        sm: 'p-2',
        md: 'p-2',
        lg: 'p-3',
      },
      variant: {
        light: [],
        dark: [],
      },
      intent: {
        primary: ['text-white', 'bg-primary-500', 'rac-hover:bg-primary-600', 'focus:outline-none'],
        secondary: ['rac-hover:border-opacity-0', 'border', 'border-grey-300'],
        tertiary: ['focus:outline-none'],
      },
    },
    compoundVariants: [
      {
        variant: 'light',
        intent: 'primary',
        className: 'rac-pressed:bg-primary-700 rac-disabled:bg-grey-300 rac-disabled:text-grey-500',
      },
      {
        variant: 'dark',
        intent: 'primary',
        className: 'rac-pressed:bg-primary-400 rac-disabled:bg-grey-600 rac-disabled:text-grey-400',
      },
      {
        variant: 'light',
        intent: 'secondary',
        className:
          'text-grey-700 rac-hover:bg-grey-200 focus:border-grey-300 rac-pressed:bg-grey-200 rac-pressed:border-grey-500 rac-pressed:text-grey-900 rac-disabled:text-grey-400 rac-disabled:bg-none focus:border focus:outline-none',
      },
      {
        variant: 'dark',
        intent: 'secondary',
        className:
          'rac-hover:bg-white/25 rac-pressed:bg-grey-600 rac-pressed:border-white rac-pressed:text-white  rac-disabled:border-grey-600 rac-disabled:text-grey-600 rac-disabled:bg-none text-white focus:border focus:border-white focus:outline-none',
      },
      {
        variant: 'light',
        intent: 'tertiary',
        className:
          'text-grey-700 rac-hover:bg-grey-200 rac-pressed:bg-grey-300  rac-pressed:text-grey-900 rac-disabled:text-grey-500 rac-disabled:bg-none',
      },
      {
        variant: 'dark',
        intent: 'tertiary',
        className:
          'rac-hover:bg-white/25 rac-pressed:bg-white/50 rac-disabled:text-grey-500  rac-disabled:bg-none text-white',
      },
    ],
    defaultVariants: {
      size: 'md',
      variant: 'light',
      intent: 'tertiary',
    },
  },
);

interface IconButtonProps extends ButtonProps, VariantProps<typeof iconButton> {
  icon: (typeof iconNames)[number];
  iconClassName?: string;
  isDisabled?: boolean;
}

function BaseIconButton(
  { icon, size, iconClassName, variant, intent, children, className, ...props }: IconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const iconSize = size === 'xs' ? ' h-4 w-4 ' : '';

  return (
    <Button
      className={(bag) =>
        twMerge(
          iconButton({ size, variant, intent }),
          isFunction(className) ? className(bag) : className,
        )
      }
      ref={ref}
      {...props}>
      {(bag) => (
        <>
          {isFunction(children) ? children(bag) : children}
          <Icon className={iconSize + iconClassName} name={icon} />
        </>
      )}
    </Button>
  );
}

export const IconButton = React.forwardRef(BaseIconButton) as (
  props: IconButtonProps & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof BaseIconButton>;
